/**
 * https://jira.beesat.com/browse/MDEV-1344
 *
 * Google Map overrides any font called 'Roboto'.
 * We replace method head.insertBefore to catch loading of the Roboto font.
 */
(function () {
    'use strict';

    var head = document.getElementsByTagName('head')[0];

    // Save the original method
    var insertBefore = head.insertBefore;

    // Replace it!
    head.insertBefore = function (newElement, referenceElement) {
        // Prevent Roboto font from loading
        if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0) {
            return;
        }

        // call original method
        insertBefore.call(head, newElement, referenceElement);
    };
}());
